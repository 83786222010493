import { render, staticRenderFns } from "./UserGroupList.vue?vue&type=template&id=79ef8910&scoped=true&"
import script from "./UserGroupList.vue?vue&type=script&lang=js&"
export * from "./UserGroupList.vue?vue&type=script&lang=js&"
import style0 from "./UserGroupList.vue?vue&type=style&index=0&id=79ef8910&lang=scss&scoped=true&"
import style1 from "./UserGroupList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79ef8910",
  null
  
)

export default component.exports